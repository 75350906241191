import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Card } from '../components/Card'
import { useState, useEffect } from 'react'
import { type ProductType, type IProduct } from '../interface/types'
import { getWines } from 'services/wine.service'
import { ASSET_URL } from 'config/config'

export const OurProducts: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  useEffect(() => {
    fetchProducts();
  },[]);

  const fetchProducts = async () => {
    try {
      const response = await getWines();
      setProducts(response.wines);
    } catch(error) {
      console.error('Error fetching products', error);
    }
  };
  return (
    <section className='ourProducts'>
      <h2 className='subtitle'>
        Our Service
      </h2>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1108: {
            slidesPerView: 3,
            spaceBetween: 10
          }
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="ourProducts__swiper"
      >
        {
          products.map((product) => (
            <SwiperSlide key={product.package_id}>
              <Card package_id={product.package_id} package_name={product.package_name} package_image={ASSET_URL+product.package_image} package_cost={product.package_cost} description={product.description} compulsory_type={product.compulsory_type} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </section>
  )
}
