import { PackageType } from "types/races/Race";
import { Package } from "./Package/Package";
import "./style.scss";

interface PackagesComponentProps {
  packages: PackageType[];
}

export const Packages: React.FC<PackagesComponentProps> = ({
  packages,
}) => {
  return (
    <div className="row mx-5 my-5 package-row">
      {packages.length ? (
        packages.map((_package) => (
          <div key={_package.package_id} className="col-sm-12 col-md-6 col-lg-4">
            <Package package={_package} />
          </div>
        ))
      ) : (
        <h3>No packages available for this event!</h3>
      )}
    </div>
  );
};
