import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { getEvnets } from 'services/events.service'
import { RaceType } from 'types/races/Race'
import { Race } from './Race'

interface RacesProps {

}

export const Races: React.FC<RacesProps> = () => {

  const [races, setRaces] = useState<RaceType[]>([]);

  const loadProducts = async() => {
    const _races = await getEvnets();
    if (_races) {
      setRaces(_races);
    }
  }

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <section className='ourProducts' style={{marginTop: 0}}>
      <h2 className='subtitle'>
        My Races
      </h2>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1108: {
            slidesPerView: 3,
            spaceBetween: 10
          }
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {
          races.map((race) => (
            <SwiperSlide key={race.reserv_id}>
              <Race race={race} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </section>
  )
}
