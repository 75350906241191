import React, { useMemo } from 'react'
import { useState } from 'react'
import { RaceType } from 'types/races/Race'
import { stampToTime } from 'helpers/helper'
import { useNavigate } from 'react-router-dom'
import "./style.scss";

interface RaceComponentProps {
  race: RaceType
}

export const Race: React.FC<RaceComponentProps> = (props) => {
  const { event_id, reserv_id, name, description, end_date, date } = props.race;
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedTitle, setIsExpandedTitle] = useState(false);
  const event_date = useMemo(() => {
    return stampToTime(date);
  }, [end_date])

  const toggleDescription = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const toggleTitle = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setIsExpandedTitle(!isExpandedTitle);
  };

  const getDescriptionText = (): string => {
    if (isExpanded) {
      return description;
    }
    if (description.length > 50) {
      return description.slice(0, 50) + '...';
    }
    return description;
  };

  const viewEvent = () => {
    navigate(`/event/${event_id}/${reserv_id}`);
  }
  
  return (
    <div className='card race' onClick={ viewEvent }>
      <h3 className='card__name'>
          { name }
          { name.length > 15 && (
            <button onClick={toggleTitle} className="read-more-button" style={{color:'rgba(7, 63, 80, 0.5960784314)', fontSize:'2.25rem', fontWeight: 'bold'}}>
              {isExpandedTitle ? '-' : '+'}
            </button>
          )}
      </h3>
      <p className='card__info'>
          {getDescriptionText()}
          {description.length > 50 && (
            <button onClick={toggleDescription} className="read-more-button" style={{color:'rgba(7, 63, 80, 0.5960784314)', fontSize:'1.5rem', fontWeight: 'bold'}}>
              {isExpanded ? 'Read less...' : 'Read more...'}
            </button>
          )}
      </p>
      <p className="text-right text-dark font-weight-bold text-large">{ event_date }</p>
    </div>
  )
}
