import React, { useState } from "react";
// import GooglePayButton from "@google-pay/button-react";
import background_g from '../assets/images/googlepayback.jpg';
import payment_success from '../assets/images/success.png';
import "./style.css";

export default function GooglePaymentSuccessPage() {
  return (
    <div style={{backgroundImage: `url(${background_g})`}}>
      <div className="container">
        <button className="close">✖</button>
        <div className="cookiesContent" id="cookiesPopup">
          <p style={{fontSize:'18px', }}>Transaction Successful!</p> 
          <img src={payment_success} alt="cookies-img" />
          <button type="button" className="btn btn-success"><a href="/home">Back To The Homepage</a></button>
        </div>
      </div>
      <div className="demo" >
      
      </div>
    </div>
  );
}
