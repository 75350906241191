import React from 'react';
import errorImage from '../assets/images/errorImage.png';
import { useAuth } from '../hooks/useAuth';
import { Navigate, useNavigate, NavigateFunction } from 'react-router-dom';

export const NotFoundPage: React.FC = () => {
    const auth = useAuth();
    let navigate: NavigateFunction = useNavigate();
    if(auth?.tokens) return (<Navigate to='/home' />)
    return (
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}}>
            <img style={{width: '50%', height:'auto'}} src={errorImage} alt="404 Error" />
            <h1 style={{fontSize:'5rem', textAlign: 'center'}}>404 Page Not Found</h1>
        </div>
        
    );
};