import { createContext, useContext, useMemo, ReactNode, useState, useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  tokens: any;
  login: (tokens: any) => void;
  logout: () => void;
  user: any;
  initialLogin: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

interface UserInterface {
  accessToken: string
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [tokens, setTokens] = useLocalStorage<UserInterface|null>("user", null);
  const [user, setUser] = useState<any>(null);

  const login = async (tokens: any) => {
    setTokens(tokens);
    try {
      const decode = jwtDecode(tokens.accessToken);
      setUser(decode);
    } catch (err) {
    }
  };

  const logout = () => {
    setTokens(null);
    setUser(null);
  };

  const initialLogin = () => {
    login(tokens);
  }

  const value = useMemo(
    () => ({
      tokens,
      login,
      logout,
      user,
      initialLogin
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType | undefined => {
  return useContext(AuthContext);
};