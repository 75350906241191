import React, { useState, useEffect } from 'react'
import IconBurger from '../assets/icons/icn-burger.png'
import IconSalad from '../assets/icons/icn-salad.png'
import IconSoda from '../assets/icons/icn-soda.png'
import IconDessert from '../assets/icons/icn-dessert.png'
import IconBreakfast from '../assets/icons/icn-breakfast.png'
import { Types } from '../components/Types'
import { Card } from '../components/Card'
// import { BURGER, BREAKFAST, DESSERT, SALAD, SODA } from '../data/data'
import { type ProductType, type IProduct } from '../interface/types'
import axios from 'axios';
import { getWines } from 'services/wine.service'

interface IIconTypes {
  image: string
  name: ProductType
}

const IconTypes: IIconTypes[] = [
  {
    image: IconBurger,
    name: 'ROSE'
  },
  {
    image: IconSalad,
    name: 'WHITE'
  },
  {
    image: IconSoda,
    name: 'SPARKLING'
  },
  {
    image: IconDessert,
    name: 'RED'
  },
  {
    image: IconBreakfast,
    name: 'FINE'
  }
]

const enum PRODUCTS_ENUM {
  BURGER = 'ROSE',
  SALAD = 'WHITE',
  SODA = 'SPARKLING',
  DESSERT = 'RED',
  BREAKFAST = 'FINE'
}

export const Menu: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [type, setType] = useState<ProductType>('ROSE')
  
  
  useEffect(() => {
    fetchProducts();
  },[]);

  const fetchProducts = async () => {
    try {
      const resp = await getWines();
      setProducts(resp.wines);
    } catch(error) {
      console.error('Error fetching products', error);
    }
  };
  
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([])
  useEffect(() => {
    if (type === PRODUCTS_ENUM.BURGER) {
      setFilteredProducts(products.filter((wine) => wine.compulsory_type === 1))
    } else if (type === PRODUCTS_ENUM.BREAKFAST) {
      setFilteredProducts(products.filter((wine) => wine.compulsory_type === 5))
    } else if (type === PRODUCTS_ENUM.DESSERT) {
      setFilteredProducts(products.filter((wine) => wine.compulsory_type === 4))
    } else if (type === PRODUCTS_ENUM.SALAD) {
      setFilteredProducts(products.filter((wine) => wine.compulsory_type === 2))
    } else if (type === PRODUCTS_ENUM.SODA) {
      setFilteredProducts(products.filter((wine) => wine.compulsory_type === 3))
    }
  }, [type])

  return (
    <section className='menu'>
      <h2 className='subtitle'>Our Wines</h2>
      <div className='menu__types'>
        {
          IconTypes.map((type, index) => (
            <Types key={index} image={type.image} name={type.name} setType={setType} />
          ))
        }
      </div>
      <div className='menu__container'>
        {
          filteredProducts.map((product) => (
            <Card key={product.package_id} package_id={product.package_id} package_name={product.package_name} package_image={"https://ems-dev-clone1.sems.me/mi-eventmaster/html/images/packages/"+product.package_image} package_cost={product.package_cost} description={product.description} compulsory_type={product.compulsory_type} />
          ))
        }
    </div>
    </section>
  )
}
