import { createContext, useContext, useMemo, ReactNode } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { PackageType } from "types/races/Race";

export interface CartType {
  total: number;
  packages: CartPackageType[];
  eventId: string;
  reservationId: string;
}

export interface CartPackageType {
  package: PackageType,
  quantity: number,
  eventId: string,
  reservationId: string
}

interface CartContextType {
  cart: CartType;
  addToCart: (_package: PackageType, quantity: number) => void;
  updateCart: (_package: PackageType, quantity: number) => void;
  removeFromCart: (_package: PackageType) => void;
  clearCart: () => void;
  setCartConfiguration: (eventId: string, reservationId: string) => void;
}

const initialValue = {
  total: 0,
  packages: [],
  eventId: "",
  reservationId: "",
}
const CartContext = createContext<CartContextType|null>(null);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useLocalStorage<CartType>("cart", initialValue);

  const calculatePrice = (packages: CartPackageType[]) => {
    return packages.reduce((s, c) => s += parseFloat(c.package.package_cost)*c.quantity, 0)
  }

  const addToCart = (_package: PackageType, quantity: number = 1) => {
    const packages = [...cart.packages, { package:_package, quantity, reservationId: cart.reservationId, eventId: cart.eventId }];
    setCart({
      ...cart,
      total: calculatePrice(packages),
      packages
    })
  }

  const updateCart = (_package: PackageType, quantity: number = 1) => {
    const packages = cart.packages.map(item => ((item.package.package_id === _package.package_id && item.reservationId === cart.reservationId) ? ({ ...item, quantity }) : item))
    setCart({
      ...cart,
      total: calculatePrice(packages),
      packages
    })
  }

  const removeFromCart = (_package: PackageType) => {
    const packages = cart.packages.filter(item => (item.package.package_id !== _package.package_id || item.reservationId != cart.reservationId));
    setCart({
      ...cart,
      total: calculatePrice(packages),
      packages
    });
  }

  const setCartConfiguration = (_eventId: string, _reservationId: string) => {
    setCart({
      ...cart,
      eventId: _eventId,
      reservationId: _reservationId
    });
  }

  const clearCart = () => {
    setCart(initialValue);
  }

  const value = useMemo(
    () => ({
      cart,
      addToCart,
      removeFromCart,
      updateCart,
      setCartConfiguration,
      clearCart
    }),
    [cart, addToCart, removeFromCart, setCartConfiguration]
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCart = (): CartContextType | null => {
  return useContext(CartContext);
};