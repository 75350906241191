import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Cart } from '../layout/Cart';
import { Layout } from '../layout/Layout';
import { Temporal } from '../layout/Temporal';
import { Home } from '../pages/Home';
import { Menu } from '../pages/Menu';
import { Login } from '../components/page/auth/Login';
import GooglePaymentPage from "../pages/Google-payment";
import { ApplePaymentPage } from "../pages/Apple-payment";
import { NotFoundPage } from 'pages/NotFoundPage';
import  GooglePaymentSuccessPage  from 'pages/google-payment-success'
import { Event } from 'pages/event';
import { ForgotPassword } from 'components/page/auth/Forgot-Password';
import { ResetPassword } from 'components/page/auth/Reset-Password';
import { Purchase } from 'pages/purchase';

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout children={<Home />} publicRoute={true} />} />
      <Route path="home" element={<Layout children={<Home />} />} />
      <Route path="menu" element={<Layout children={<Menu />} />} />
      <Route path="service" element={<Layout children={<Temporal />} />} />
      <Route path="about" element={<Layout children={<Temporal />} />} />
      <Route path="contact" element={<Layout children={<Temporal />} />} />
      <Route path="cart" element={<Layout  children={<Cart />} />} />
      <Route path="google-payment" element={<Layout children={<GooglePaymentPage />} />} />
      <Route path="apple-payment" element={<Layout children={<ApplePaymentPage />} />} />
      <Route path="google-payment-success" element={<Layout children={<GooglePaymentSuccessPage />} />} />
      <Route path="/verification/:verificationcode" element={<Login />} />
      <Route path="/purchase/:reservationId" element={<Layout  children={<Purchase />} />} />
      <Route path="login/:hash?" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="/event/:event_id/:reservation_id" element={<Layout children={<Event />} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

// export const Router: React.FC = () => {
//     return (
//     <Routes>
//       <Route path='/'>
//         <Route index element={<Login />} />
//         <Route path='/' element={<Layout />} >
//           <Route path='home' element={<Home />} />
//           <Route path='menu' element={<Menu />} />
//           <Route path='service' element={<Temporal />} />
//           <Route path='about' element={<Temporal />} />
//           <Route path='contact' element={<Temporal />} />
//           <Route path='cart' element={<Cart />} />
//           <Route path='google-payment' element={<GooglePaymentPage />} />
//           <Route path='signup' element={<Register />} />
//         </Route>
//       </Route>
//     </Routes>
//   )
// }

