import React, { useState } from "react";
import { NavigateFunction, useNavigate, Navigate, useParams, Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Login.css";
import { login } from "../../../services/auth.service";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from '../../../hooks/useAuth';

type Props = {}

export const Login: React.FC<Props> = () => {
  const auth = useAuth();
  let navigate: NavigateFunction = useNavigate();
  const { hash } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isMouseUp, setIsMouseUp] = useState(false);
  const params = useParams<{"verificationcode": string}>();
  const verificationcode = params.verificationcode??'';
  const spliteIndex = Math.floor(verificationcode.length/2);
  const event = verificationcode.substring(0, spliteIndex);
  const booking = verificationcode.substring(spliteIndex);

  const initialValues: {
    email: string;
    password: string;
    event_id: string;
    booking_id: string;
  } = {
    email: "",
    password: "",
    event_id: `${event}`,
    booking_id: `${booking}`
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue: { email: string; password: string, event_id: string, booking_id: string }) => {
    const { email, password, event_id, booking_id } = formValue;
    setMessage("");
    setLoading(true);

    login(email, password, event_id, booking_id).then(
      (data) => {
        auth?.login(data);
        if (hash) {
          const res = atob(hash);
          const [p1, p2] = res.split('&');
          if (p1 && p2) {
            const [ booking_ref_key, booking_ref ] = p1.split('=');
            const [ event_id_key, event_id ] = p2.split('=');
            if (event_id) {
              navigate(`/event/${event_id}`)
            }
          }
        } else {
          navigate("/home");
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const handleMouseUp = () => {
    setIsMouseUp(true);
  }

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedImage(file);
  };
  if(auth?.tokens) return (<Navigate to='/home' />)
  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src={selectedImage ? URL.createObjectURL(selectedImage) : "//ssl.gstatic.com/accounts/ui/avatar_2x.png"}
          alt="profile-img"
          className="profile-img-card"
          style={{ borderColor: isMouseUp ? '#ff0000' : '#0000ff' }}
          onClick={() => {
            //Trigger the file input when the image is clicked.
            document.getElementById("imageUpload")?.click();
          }}
          onMouseOver={handleMouseUp}

        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          <Form>
            <div className="form-group">
                  <label htmlFor="email"> Email </label>
                  <Field name="email" type="text" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            {
              initialValues.event_id && <div className="form-group">
                <label htmlFor="event_id">Event ID</label>
                <Field name="event_id" type="text" className="form-control" disabled/>
                <ErrorMessage
                  name="event_id"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
            }

            {
              initialValues.booking_id && <div className="form-group">
                <label htmlFor="booking_id">Booking ID</label>
                <Field name="booking_id" type="text" className="form-control" disabled/>
                <ErrorMessage
                  name="booking_id"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
            }

            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            <div className="d-flex justify-content-center">
              <Link to="/forgot-password" className="text-primary">Forgot Password?</Link>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </div>
    </div>
  );
};
