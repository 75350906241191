import React, { useContext } from 'react'
import { type IProduct, type IProductCart, type IUserContext } from '../interface/types'
import { BiCartAdd } from 'react-icons/bi'
import toast from 'react-hot-toast'
import Atropos from 'atropos/react'
import { UserContext } from '../context/UserContext'
import { useState, useEffect } from 'react'

export const Card: React.FC<IProduct> = ({ package_id, package_image, package_name, package_cost, description, compulsory_type }) => {
  const { addCart } = useContext(UserContext) as IUserContext
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedTitle, setIsExpandedTitle] = useState(false);

  const toggleDescription = (): void => {
    setIsExpanded(!isExpanded);
  };

  const toggleTitle = (): void => {
    setIsExpandedTitle(!isExpandedTitle);
  };

  const getDescriptionText = (): string => {
    if (isExpanded) {
      return description;
    }
    if (description.length > 50) {
      return description.slice(0, 50) + '...';
    }
    return description;
  };

  const getTitleText = (): string => {
    if (isExpandedTitle) {
      return package_name;
    }
    if (package_name.length > 15) {
      return package_name.slice(0, 15) + '...';
    }
    return package_name;
  };

  const handleAddProduct = (): void => {
    const newProduct: IProductCart = {
      package_id,
      package_image,
      package_name,
      package_cost,
      description,
      quantity: 1,
      compulsory_type
    }
    addCart(newProduct)
    toast.success('Added successfully', {
      className: 'toast'
    })
  }

  return (
    <div className='card'>
      <Atropos
        shadow={false}
      >
        <img src={package_image} alt={package_name} className='card__img' />
      </Atropos>
      <h3 className='card__name'>
          {getTitleText()}
          {package_name.length > 15 && (
            <button onClick={toggleTitle} className="read-more-button" style={{color:'rgba(7, 63, 80, 0.5960784314)', fontSize:'2.25rem', fontWeight: 'bold'}}>
              {isExpandedTitle ? '-' : '+'}
            </button>
          )}
      </h3>
      <p className='card__info'>
          {getDescriptionText()}
          {description.length > 50 && (
            <button onClick={toggleDescription} className="read-more-button" style={{color:'rgba(7, 63, 80, 0.5960784314)', fontSize:'1.5rem', fontWeight: 'bold'}}>
              {isExpanded ? 'Read less...' : 'Read more...'}
            </button>
          )}
      </p>
      <div className='card__container'>
        <span className='card__container__price'>£{package_cost} {compulsory_type}</span>
        <button aria-label='Add to cart' className='card__container__button' onClick={handleAddProduct}>
          <BiCartAdd aria-hidden='true' />
        </button>
      </div>
    </div>
  )
}
