import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './context/UserProvider';
import { Router } from './router/Router';
import { useAuth } from 'hooks/useAuth';
import { Toaster } from 'react-hot-toast';

const App: React.FC = () => {

  const auth = useAuth();

  useEffect(() => {
    auth?.initialLogin();
  }, []);

  return (
    <UserProvider>
      <BrowserRouter>
        <Router />
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </BrowserRouter>
    </UserProvider>
  )
}

export default App
