import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useCart } from 'hooks/useCart'
import "./style.css";

export const Purchase: React.FC = () => {
  const cart = useCart();
  const [items, setItems] = useState(cart?.cart.packages??[]);
  const [total, setTotal] = useState(cart?.cart.total||0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');

  useEffect(() => {
    cart?.clearCart();
  }, []);

  return (
    <section className='cart'>
      <h2 className='subtitle'>
        Your Invoice
      </h2>
      <div>
        <div className='cart__grid__info w-100'>
          <h3 className='cart__grid__info__title'>
            Summary
          </h3>
          <div className='cart__grid__info__container'>
            <div className='cart__grid__info__container__div'>
              <span className='cart__grid__info__container__div-name'>
                Shipping Cost:
              </span>
              <span className='cart__grid__info__container__div-price'>
                £ 0
              </span>
            </div>
            <div className='cart__grid__info__container__div'>
              <span className='cart__grid__info__container__div-name'>
                Subtotal:
              </span>
              <span className='cart__grid__info__container__div-price'>
                £ {total.toFixed(2)}
              </span>
            </div>
            {
              items.map(item => (
                <div className='cart__grid__info__container__div summary-text'>
                  £ {item.package.package_cost} X {item.quantity} = £ {(parseFloat(item.package.package_cost) * item.quantity).toFixed(2)}
                </div>
              ))
            }
          </div>
          <div className='cart__grid__info__div'>
            <span className='cart__grid__info__div-name'>
              Total:
            </span>
            <span className='cart__grid__info__div-price'>
              £ {total.toFixed(2)}
            </span>
          </div>
        </div>
      </div> 
    </section>
  )
}
