import { jwtInterceptor } from "./auth.service";
import { ObjectType, PackageType, RaceType } from "types/races/Race";

export interface EventDetailsResponse {
  packages: PackageType[];
  event: RaceType;
}

export const getEvnets = async () => {
  try {
    const resp = await jwtInterceptor.get('/events/list');
    return resp.data.events;
  } catch(err) {
    return [];
  }
}

export const getItemsOfEvent = async(event_id: string, objectTypes: ObjectType[]) => {
  try {
    const resp = await jwtInterceptor.get(`events/packages?event_id=${event_id}&objectTypes=${JSON.stringify(objectTypes)}`);
    return { packages: resp.data.packages, event: resp.data.event};
  } catch(err) {
    return { packages: [], event: null };
  }
}

export const getObjectTypes = async() => {
  try {
    const resp = await jwtInterceptor.get(`events/object_types`);
    return { objectTypes: resp.data.objectTypes };
  } catch (err) {
    return { objectTypes: [] }
  }
}