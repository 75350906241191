import React from 'react'
import { CartPackageType, useCart } from 'hooks/useCart'
import QuantityInput from '../../common/ui/NumberInput'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';

interface CartItemProps {
  product: CartPackageType
}

export const CartItem: React.FC<CartItemProps> = (props) => {

  const { product } = props;
  const cart = useCart();
  const onChange = (event: React.KeyboardEvent<Element> | React.FocusEvent<HTMLInputElement> | React.PointerEvent<Element>, value: number | undefined) => {
    cart?.updateCart(product.package, value||1);
  }

  const removeFromCart = () => {
    cart?.removeFromCart(product.package);
  }

  return (
    <li className='item'>
      <div>
        { product.package.package_name } (£ { product.package.package_cost })
        <IconButton aria-label="delete" color="secondary" size="large" onClick={removeFromCart}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </div>
      <div>
        <QuantityInput
          onChange={onChange}
          value={product.quantity}
          min={1} />
      </div>
    </li>
  )
}
