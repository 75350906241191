import { CartPackageType, CartType } from "hooks/useCart";
import { jwtInterceptor } from "./auth.service";

export const confirmGooglePayment = async (paymentToken: string, cartItems: CartPackageType[]) => {
  try {
    const resp = await jwtInterceptor.post('/payments/google-payment', { paymentToken, cartItems });
    return resp.data.result;
  } catch(err) {
    return "fail";
  }
}

export const orderPackages = async(cart: CartType | undefined) : Promise<string> => {
  try {
    const resp = await jwtInterceptor.post('/payments/sage-pay', { amount: cart?.total.toFixed(2), packages: cart?.packages, reservationId: cart?.packages[0].reservationId });
    return resp.data.nextUrl;
  } catch (err) {
    return "fail";
  }
}