import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";
// import { useNavigate } from 'react-router-dom'

// const API_URL = "http://localhost:8800/api";
const API_URL = "https://drinks-dev.sems.me/api";

export const jwtInterceptor = axios.create({
  baseURL: API_URL
});

jwtInterceptor.interceptors.request.use((config) => {
  const user = getCurrentUser();
  if (user && user.accessToken) {
    config.headers.Authorization = `Bearer ${user.accessToken}`;
  }
  return config;
})

jwtInterceptor.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    try {
      if (error.response?.status == 401) {
          logout();
          window.location.reload();
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      logout();
      window.location.reload();
    }
    throw error;
  }
);

export const register = (username: string, email: string, password: string) => {
  return axios.post(API_URL + "/signup", {
    username,
    email,
    password,
  });
};

export const login = (email: string, password: string, event_id: string, booking_id: string) => {
  return axios
    .post(API_URL + "/auth/signin", {
      email,
      password,
      event_id,
      booking_id
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
  window.location.href = '/login';
};

export const forgotPassword = (email: string) => {
  return axios
    .post(API_URL + "/auth/forgot-password", {
      email,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export const resetPassword = (password: string, token: string) => {
  return axios
    .post(API_URL + "/auth/reset-password", {
      password,
      token
    })
}

export const getCurrentUser = () => {
  try {
    const tokens = JSON.parse(localStorage.getItem("user")??"");
    const user = jwtDecode(tokens.accessToken);
    if (user) {
      return {
        ...user,
        accessToken: tokens.accessToken
      };
    }
  } catch(err) {
    return null;
  }
};