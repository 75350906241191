import React, { useState } from "react";
import GooglePayButton from "@google-pay/button-react";
import background_g from '../assets/images/googlepayback.jpg';
import purchase_g from '../assets/burger/burger5__.png';
import axios from 'axios';
import "./style.css";

const paymentRequest: google.payments.api.PaymentDataRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"],
      },
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
          'gateway': 'crst',
          'gatewayMerchantId': '252190'
        },
      },
    },
  ],
  merchantInfo: {
    merchantId: 'BCR2DN4T22DMNEIX',
    merchantName: 'SecurePlay',
  },
  transactionInfo: {
    totalPriceStatus: 'FINAL',
    totalPriceLabel: 'Total',
    totalPrice: '100.00',
    currencyCode: 'USD',
    countryCode: 'US',
  },
};

export default function GooglePaymentPage() {

  const [buttonColor, setButtonColor] = useState("default");
  const [buttonType, setButtonType] = useState("buy");
  const [buttonSizeMode, setButtonSizeMode] = useState("static");
  const [buttonWidth, setButtonWidth] = useState(240);
  const [buttonHeight, setButtonHeight] = useState(40);

  const isTop = window === window.top;

  return (
    <div style={{backgroundImage: `url(${background_g})`}}>
      <div className="container">
        <button className="close">✖</button>
        <div className="cookiesContent" id="cookiesPopup">
          <p style={{fontSize:'15px'}}>The Hogmananay Raceday</p> 
          <img src={purchase_g} alt="cookies-img" />
            <GooglePayButton
              environment="TEST"
              buttonColor={buttonColor as google.payments.api.ButtonColor}
              buttonType={buttonType as google.payments.api.ButtonType}
              buttonSizeMode={buttonSizeMode as google.payments.api.ButtonSizeMode}
              paymentRequest={paymentRequest}
              onLoadPaymentData={(paymentData: google.payments.api.PaymentData) => {
                const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
                console.log("load payment data", paymentToken);
                axios.post('https://drinks-dev.sems.me/api/google-payment', {
                  paymentToken: paymentToken,
                })
                  .then(response => {
                    // Handle the success response from the backend, such as redirecting to the payment success page
                    // window.open(response.data.url, "_self");
                    console.log("response url: ", response.data.url);
                  })
                  .catch(error => {
                    // Handle any error that occurred during the Axios request
                    console.log('Error:', error);
                  });

            }}
           style={{ width: buttonWidth, height: buttonHeight }}
        />
        </div>
      </div>
      <div className="demo" >
      </div>
    </div>
  );
}


// import React, { useEffect, useState } from "react";

// const GooglePaymentPage: React.FC = () => {
//   const [googlePayLoaded, setGooglePayLoaded] = useState(false);
//   const [googlePayScript, setGooglePayScript] = useState<HTMLScriptElement | null>(null);

//   useEffect(() => {
//     const loadGooglePayScript = () => {
//       const script = document.createElement("script");
//       script.src = "https://pay.google.com/gp/p/js/pay.js";
//       script.onload = () => setGooglePayLoaded(true);
//       setGooglePayScript(script);
//       document.body.appendChild(script);
//     };

//     loadGooglePayScript();

//     return () => {
//       if (googlePayScript) {
//         document.body.removeChild(googlePayScript);
//       }
//     };
//   }, [googlePayScript]);

//   const onGooglePaymentButtonClicked = () => {
//     if (googlePayLoaded) {
//       const paymentDataRequest = getGooglePaymentDataRequest();
//       const paymentsClient = new google.payments.api.PaymentsClient({
//         environment: "TEST", // Change to "PRODUCTION" for live payments
//       });

//       paymentsClient
//         .loadPaymentData(paymentDataRequest)
//         .then((paymentData: google.payments.api.PaymentData) => {
//           processPayment(paymentData.paymentMethodData.tokenizationData.token);
//         })
//         .catch((error: any) => {
//           console.error(error);
//         });
//     }
//   };

//   const getGooglePaymentDataRequest = (): google.payments.api.PaymentDataRequest => {
//     return {
//       apiVersion: 2,
//       apiVersionMinor: 0,
//       allowedPaymentMethods: [
//         {
//           type: "CARD",
//           parameters: {
//             allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
//             allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"],
//           },
//           tokenizationSpecification: {
//             type: "PAYMENT_GATEWAY",
//             parameters: {
//               gateway: "YOUR_PAYMENT_GATEWAY",
//               gatewayMerchantId: "YOUR_GATEWAY_MERCHANT_ID",
//             },
//           },
//         },
//       ],
//       merchantInfo: {
//         merchantId: "YOUR_MERCHANT_ID",
//         merchantName: "Your Merchant Name",
//       },
//       transactionInfo: {
//         totalPriceStatus: "FINAL",
//         totalPrice: "1.00",
//         currencyCode: "USD",
//       },
//     };
//   };

//   const processPayment = (paymentToken: string) => {
//     // Send payment token to backend for processing
//     // Make a POST request to your backend API with the payment token
//     // You can use libraries like Axios or fetch to make the request
//     // Example using fetch:
//     /*
//     fetch("/process-payment", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ paymentToken }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         // Handle success response
//         window.open(data.url, "_self");
//       })
//       .catch((error) => {
//         // Handle error
//         console.error(error);
//       });
//     */
//   };

//   return (
//     <div>
//       <h1>Google Pay Integration</h1>
//       {googlePayLoaded && (
//         <button onClick={onGooglePaymentButtonClicked}>Pay with Google Pay</button>
//       )}
//     </div>
//   );
// };

// export default GooglePaymentPage;