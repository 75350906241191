import React, { useEffect, useRef } from 'react';
import background_g from '../assets/images/googlepayback.jpg';
import purchase_g from '../assets/burger/burger5__.png';
import axios from 'axios';
import "./style.css";

// Declare the ApplePaySession type globally
declare global {
  interface Window {
    ApplePaySession: ApplePaySessionConstructor;
  }
}

export interface ApplePaySessionConstructor {
  new(version: number, request: ApplePayPaymentRequest): ApplePaySession;
  STATUS_SUCCESS: number;
}

export interface ApplePaySession {
  canMakePayments(): boolean;
  createButton(type?: string): HTMLElement;
  supportsVersion(version: number): boolean;
  begin(): void;
  completeMerchantValidation(merchantSession: ApplePayMerchantSession): void;
  completePayment(status: number): void;
  onvalidatemerchant: (event: any) => void;
  onpaymentauthorized: (event: any) => void;
}

export interface ApplePayPaymentRequest {
  countryCode: string;
  currencyCode: string;
  merchantCapabilities: string[];
  supportedNetworks: string[];
  total: ApplePayLineItem;
}

export interface ApplePayLineItem {
  label: string;
  amount: string;
}

interface ApplePayMerchantSession {
  // Add any required properties for merchant session
}

export const ApplePaymentPage: React.FC = () => {
  const applePayButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleApplePayButtonClicked = () => {
      // Create an ApplePaySession
      const session = new window.ApplePaySession(1, {
        countryCode: 'US',
        currencyCode: 'USD',
        merchantCapabilities: ['supports3DS'],
        supportedNetworks: ['visa', 'masterCard', 'amex'],
        total: {
          label: 'Wine Purchase',
          amount: '9.99',
        },
      });

      // Handle validation and payment authorization events
      session.onvalidatemerchant = (event: any) => {
        // Perform server-side validation with event.validationURL
        session.completeMerchantValidation({});
      };

      session.onpaymentauthorized = (event: any) => {
        const payment = event.payment;

        // Perform server-side payment processing with payment.token
        // Once the payment is processed, complete the session
        session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
      };

      // Start the Apple Pay session
      session.begin();
    };

    // Load the Apple Pay JS script
    const script = document.createElement('script');
    script.src = 'https://applepay.cdn.apple.com/jsapi/v1/apple-pay-sdk.js';
    script.async = true;

    script.onload = () => {
      // Apple Pay JS script has loaded, set up event listeners and integration here
      if (applePayButtonRef.current) {
        const session = new window.ApplePaySession(1, {
          countryCode: 'US',
          currencyCode: 'USD',
          merchantCapabilities: ['supports3DS'],
          supportedNetworks: ['visa', 'masterCard', 'amex'],
          total: {
            label: 'Wine Purchase',
            amount: '9.99',
          },
        });

        const applePayButton = session.canMakePayments()
          ? session.supportsVersion(3)
            ? session.createButton()
            : session.createButton('plain')
          : null;

        if (applePayButton) {
          applePayButton.addEventListener('click', handleApplePayButtonClicked);
          applePayButtonRef.current.appendChild(applePayButton);
        }
      }
    };

    document.head.appendChild(script);

    return () => {
      // Clean up the script and event listener when the component unmounts
      if (applePayButtonRef.current) {
        applePayButtonRef.current.innerHTML = '';
      }
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div ref={applePayButtonRef}></div>
  );
};
