import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BiCart } from 'react-icons/bi'
import { Pivot } from 'hamburger-react'
import { Logo } from '../components/Logo'
import { Nav } from '../components/Nav'
import { Toaster } from 'react-hot-toast'
import { useCart } from 'hooks/useCart'

export const Header: React.FC = () => {
  const [menu, setMenu] = useState<boolean>(false)
  const cart = useCart();

  return (
    <header className='header' style={{ zIndex: '999'}}>
      <div className='header__container'>
        <div
          className='header__container-effect'
        />
        <Logo classes='header__logo logo' />
        <Nav show={menu} setMenu={setMenu} />
        <div
          className='header__container__buttons'
        >
          <Link to='/cart' className='header__container__buttons__shop' aria-label='Show cart'>
            <span className="cart-price">£ { cart?.cart.total.toFixed(2) }</span>
            <BiCart aria-hidden='true' />
          </Link>
          <div className='header__container__buttons__burger'>
            <Pivot toggle={setMenu} toggled={menu} />
          </div>
        </div>
      </div>
    </header>
  )
}
