import React, { useEffect } from 'react'
import { CartItem } from '../components/page/cart/CartItem'
import toast from 'react-hot-toast'
import emptyCart from '../assets/images/empty_cart.svg'
import { Link, useLocation } from 'react-router-dom'
import { useCart } from 'hooks/useCart'
import GooglePaymentButton from 'components/common/ui/payments/google-pay'
import { ApplePayButton } from 'components/common/ui/payments/apple-pay'
import { confirmGooglePayment, orderPackages } from 'services/payments.service'
import Button from '@mui/material/Button';

export const Cart: React.FC = () => {
  const cart = useCart();
  const cartItems = cart?.cart.packages??[];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');

  const onLoadGooglePaymentData = (paymentToken: string) => {
    confirmGooglePayment(paymentToken, cartItems);
  }

  const _orderPackages = async () => {
    if (!cart?.cart.total) {
      toast.error("Add one or more packages");
      return;
    }
    const nextUrl = await orderPackages(cart?.cart);
    if (nextUrl !== "fail") {
      window.open(nextUrl);
    }
  }

  useEffect(() => {
    if (error) {
      toast.error('Sorry, we can\'t proceed your payment!');
    }
  }, []);

  return (
    <section className='cart'>
      <h2 className='subtitle'>
        My Cart
      </h2>
      <div className='cart__grid'>
        <ul className='cart__grid__items'>
          {
            cartItems.length > 0
              ? (
                cartItems.map((item) => (
                    <CartItem product={item} />
                  ))
                )
              : (
                  <li className='cart__grid__items__empty'>
                    <img src={emptyCart} alt="empty Cart" className='cart__grid__items__empty-img' />
                    <h3 className='cart__grid__items__empty-title'>Empty Cart</h3>
                    <Link to={'/menu'} className='cart__grid__items__empty-link'>Explore menu</Link>
                  </li>
                )
          }
        </ul>
        <div className='cart__grid__info'>
          <h3 className='cart__grid__info__title'>
            Summary
          </h3>
          <div className='cart__grid__info__container'>
            <div className='cart__grid__info__container__div'>
              <span className='cart__grid__info__container__div-name'>
                Shipping Cost:
              </span>
              <span className='cart__grid__info__container__div-price'>
                £ 0
              </span>
            </div>
            <div className='cart__grid__info__container__div'>
              <span className='cart__grid__info__container__div-name'>
                Subtotal:
              </span>
              <span className='cart__grid__info__container__div-price'>
                £ {cart?.cart.total.toFixed(2)}
              </span>
            </div>
          </div>
          <div className='cart__grid__info__div'>
            <span className='cart__grid__info__div-name'>
              Total:
            </span>
            <span className='cart__grid__info__div-price'>
              £ {cart?.cart.total.toFixed(2)}
            </span>
          </div>
          <div>
            <Button variant="contained" onClick={_orderPackages}>SagePay</Button>
          </div>
        </div>
      </div> 
    </section>
  )
}
