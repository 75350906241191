import React, { useState } from "react";
import {
  NavigateFunction,
  useNavigate,
  Navigate,
  Link,
} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../../../hooks/useAuth";
import toast from "react-hot-toast";
import { forgotPassword } from "services/auth.service";

type Props = {};

export const ForgotPassword: React.FC<Props> = () => {
  const auth = useAuth();
  let navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const initialValues: {
    email: string;
  } = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
  });

  const handleReset = async (formValue: {
    email: string;
  }) => {
    toast.success("We've sent an email!")
    try {
      await forgotPassword(formValue.email);
    } catch(err) {

    }
  };

  if (auth?.tokens) return <Navigate to="/home" />;
  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleReset}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="email"> Your email address to reset </label>
              <Field name="email" type="text" className="form-control" />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Reset</span>
              </button>
            </div>

            <div className="d-flex justify-content-center">
              <Link to="/login" className="text-primary">Login</Link>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};
