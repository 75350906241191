import React, { useState } from "react";
import {
  NavigateFunction,
  useNavigate,
  Navigate,
  Link,
} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../hooks/useAuth";
import toast from "react-hot-toast";
import { resetPassword } from "services/auth.service";

type Props = {};

export const ResetPassword: React.FC<Props> = () => {
  const auth = useAuth();
  let navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const token = (new URL(document.location.href)).searchParams.get("token")||"";

  const initialValues: {
    password: string;
    confirm_password: string;
  } = {
    password: "",
    confirm_password: ""
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("This field is required!"),
    confirm_password: Yup.string().required("This field is required!"),
  });

  const handleReset = async (formValue: {
    password: string;
    confirm_password: string;
  }) => {
    const { password, confirm_password } = formValue;
    if (password != confirm_password) {
      toast.error("Password doesn't match");
      return;
    }
    try {
      const res = await resetPassword(password, token);
      if (res.data.status === "OK") {
        toast.success("Your password has been updatd!");
        navigate("/login");
      }
    } catch(err) {
      toast.error("The link has been expired or invalid!");
    }
  };

  if (auth?.tokens) return <Navigate to="/home" />;
  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleReset}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="password"> Password </label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirm_password"> Confirm Password </label>
              <Field name="confirm_password" type="password" className="form-control" />
              <ErrorMessage
                name="confirm_password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Reset</span>
              </button>
            </div>

            <div className="d-flex justify-content-center">
              <Link to="/login" className="text-primary">Login</Link>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};
