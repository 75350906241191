import React, { ReactNode } from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { Footer } from './Footer';
import { Header } from './Header';
import { useAuth } from '../hooks/useAuth';

interface PrivateLayoutProps {
  children: ReactNode;
  publicRoute?: Boolean
}

export const Layout: React.FC<PrivateLayoutProps> = ({ children, publicRoute }) => {
  const auth = useAuth();
  console.log(auth)

  if (!publicRoute && (!auth || !auth.tokens)) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <Header />
      <main style={{position: "relative"}}>
        <Outlet />
        {children}
      </main>
      <Footer />
    </>
  )
}
