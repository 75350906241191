import React, { useContext, useMemo } from 'react'
import { useState, useEffect } from 'react'
import { PackageType } from 'types/races/Race'
import { useNavigate } from 'react-router-dom'
import Atropos from 'atropos/react'
import { useCart } from 'hooks/useCart'
import { ASSET_URL } from 'config/config'

interface PackageComponentProps {
  package: PackageType
}

export const Package: React.FC<PackageComponentProps> = (props) => {
  const cart = useCart();
  let { package_image, package_name, package_cost, object_type_name, description, package_id } = props.package;
  const name = package_name??'';
  description = description??'';
  const [isExpanded, setIsExpanded] = useState(false);
  const isAdded = useMemo(() => {
    const packages = cart?.cart.packages;
    return packages?.findIndex(item => item.package.package_id===package_id&&item.reservationId===cart?.cart.reservationId)!==-1
  }, [cart?.cart.packages])

  const toggleDescription = (): void => {
    setIsExpanded(!isExpanded);
  };

  const cartAction = (): void => {
    if (isAdded) {
      cart?.removeFromCart(props.package);
    } else {
      cart?.addToCart(props.package, 1);
    }
  };

  const getDescriptionText = (): string => {
    if (isExpanded) {
      return description;
    }
    if (description.length > 50) {
      return description.slice(0, 50) + '...';
    }
    return description;
  };
  
  return (
    <div className='card cursor-pointer h-100 mb-0 pb-0'>
      <Atropos
        shadow={false}
      >
        <img src={ASSET_URL+package_image} alt={package_name} className='card__img' />
      </Atropos>
      <h3 className='card__name'>
        { name }
        <div className="d-flex justify-content-end pr-5">
          <span className="mr-3 packacge-price">£ { package_cost }</span>
          <button onClick={cartAction} className="read-more-button" style={{color:'rgba(7, 63, 80, 0.5960784314)', fontSize:'2.25rem', fontWeight: 'bold'}}>
            {isAdded ? '-' : '+'}
          </button>
        </div>
      </h3>
      <p className='card__info'>
          {getDescriptionText()}
          {description.length > 50 && (
            <button onClick={toggleDescription} className="read-more-button" style={{color:'rgba(7, 63, 80, 0.5960784314)', fontSize:'1.5rem', fontWeight: 'bold'}}>
              {isExpanded ? 'Read less...' : 'Read more...'}
            </button>
          )}
      </p>
    </div>
  )
}
