import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EventDetailsResponse, getItemsOfEvent, getObjectTypes } from 'services/events.service';
import { ObjectType, PackageType, RaceType } from 'types/races/Race';
import "./style.scss";
import { Packages } from 'components/events/Packages/Packages';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { stampToTime } from 'helpers/helper';
import Card from '@mui/material/Card';
import { useCart } from 'hooks/useCart';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`package-tabpanel-${index}`}
      aria-labelledby={`package-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Event: React.FC = () => {

  const { event_id, reservation_id } = useParams();
  const cart = useCart();
  const [packages, setPackages] = useState<PackageType[]>([])
  const [event, setEvent] = useState<RaceType|null>(null)
  const [objectTypes, setObjectTypes] = useState<ObjectType[]>([])
  const [tab, setTab] = useState<number>(0);

  const loadItems = async (_objectTypes: ObjectType[]) => {
    const resp: EventDetailsResponse = await getItemsOfEvent(event_id??'', _objectTypes);
    const _packages = resp.packages;
    const _event = resp.event;

    setPackages(_packages);
    setEvent(_event)
  }

  const loadObjectTypes = async () => {
    const resp = await getObjectTypes();
    const _objectTypes = resp.objectTypes;
    setObjectTypes(_objectTypes);
    loadItems(_objectTypes);
  }

  const onChangeTab = (event: SyntheticEvent<Element, Event> | null, value: number) => {
    setTab(value);
  }

  const a11yProps = (index: number) => {
    return {
      id: `package-tab-${index}`,
      'aria-controls': `package-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    loadObjectTypes();
    cart?.setCartConfiguration(event_id||'', reservation_id||'');
  }, []);

  return (
    <Card sx={{ borderBottom: 1, borderColor: 'divider', margin: 2, padding: 2 }}>
      <Box>
        <Typography gutterBottom variant="h3" component="div">
          Packages for <i>{ event?.name }</i> ({ stampToTime(event?.date) })
        </Typography>
        <Typography variant="h4" color="text.secondary">
          { event?.description }
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={onChangeTab} aria-label="basic tabs example">
          {
            objectTypes.map((type, index) => <Tab label={type.name.replace(/Preorders-/gi, "")} {...a11yProps(index)} />)
          }
        </Tabs>
      </Box>
      {
        objectTypes.map((type, index) => <CustomTabPanel value={tab} index={index}>
          <Packages packages={packages.filter(_package => _package.object_type_id===type.object_type_id)} />
        </CustomTabPanel>)
      }
    </Card>
  )
}
